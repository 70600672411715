import React from 'react';
import { LanguageSwitcher } from '../RefundBasket/RefundBasket';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t, i18n } = useTranslation();

  // Determine brand name based on current language
  const brandName = i18n.language === 'ar' 
    ? process.env.REACT_APP_BRAND_NAME_ARABIC 
    : process.env.REACT_APP_BRAND_NAME;

  const brandLogo = process.env.REACT_APP_BRAND_LOGO

  return (
    <div className="w-full h-[24vh] md:h-[14vh] bg-white shadow-md px-4 md:px-20 flex flex-col md:flex-row items-center justify-between">
      <div className="hidden md:flex items-center mb-2 md:mb-0">
        <LanguageSwitcher />
      </div>
      <div className="flex flex-col items-center space-y-2 font-dinnextltarabic text-center p-4">
        <img
          src={brandLogo}
          alt="logo"
          className="w-auto h-14 md:h-20"
        />
        <div className="text-sm md:text-md text-[#636362] font-semibold tracking-normal">
          {t('productReturnPlatform', { brandName })}
        </div>
        <div className="flex md:hidden items-center mb-2">
          <LanguageSwitcher />
        </div>
      </div>
    </div>
  );
};
